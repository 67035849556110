import axios from 'axios';
import { auth } from './Auth/firebase-service';

/* -------------------------------------------------------------------------- */
/*                                    User                                    */
/* -------------------------------------------------------------------------- */
const getProfile = async () => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/profile`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const { data } = await response.json();
  // console.log('data: ' + data);

  return data;
};

/* -------------------------------------------------------------------------- */
/*                              Video Processing                              */
/* -------------------------------------------------------------------------- */
const getVideos = async () => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/videos`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const { data } = await response.json();

  return data;
};

const getThumbnail = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/thumbnail?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const thumbnailBlob = await response.blob();
  // console.log('thumbnail: ' + data);

  return thumbnailBlob;
};

const getVideo = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/video?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const videoBlob = await response.blob();

  return videoBlob;
};

const getSDKData = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/data?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const { data } = await response.json();

  return data;
};

// Notify the server that the upload is completed
const notifyServer = async (
  jobID: string,
  videoID: string,
  fileName: string
) => {
  try {
    let formData = new FormData();

    formData.append('jobID', jobID);
    formData.append('videoID', videoID);
    formData.append('fileName', fileName);

    await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/upload/notify`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    });
    //console.log('Notification sent after upload');
  } catch (err) {
    console.error('Failed to send notification:', err);
  }
};

const getUploadLink = async (file: File) => {
  const fileName = file.name; // Get the file name from the File object
  let formData = new FormData();

  formData.append('fileName', fileName); // Append the fileName to the form data

  // Call the server to get the signed URL and other details
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/upload`,
    {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  if (!response.ok) {
    console.error('Failed to upload file');
    return;
  }

  const resp = await response.json();

  // Handle successful response (upload the file, etc.)
  // console.log('Signed URL:', resp.data.signedURL);
  // console.log('File Name:', resp.data.fileName);
  // console.log('Video ID:', resp.data.videoID);
  // console.log('Job ID:', resp.data.jobID);

  return {
    videoID: resp.data.videoID,
    jobID: resp.data.jobID,
    fileName: resp.data.fileName,
    signedURL: resp.data.signedURL,
  };
};

const uploadVideo = async (
  signedURL: string,
  file: File,
  progressCallback: (arg0: number) => void
) => {
  try {
    const response = await axios.put(signedURL, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total!
          );
          progressCallback(percentCompleted);
          //console.log(`Upload progress: ${percentCompleted}%`);
        } else {
          console.warn('Upload progress: unknown size');
        }
      },
    });

    // Return a success message along with the response data
    return {
      success: true,
      message: 'Upload completed successfully',
      data: response.data, // Include any data returned by the response
    };
  } catch (error: any) {
    console.error('Error uploading file:', error);

    // Return an error message
    return {
      success: false,
      message: 'Error uploading file: ' + error.message,
    };
  }
};

const getUploadProgress = async (jobID: string, progressCallback: (arg0: number, arg1: {}) => void, index: number) => {
  const socket = new WebSocket(`${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/progress?job=${jobID}&auth=${await auth.currentUser?.getIdToken()}`);

  socket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    progressCallback(index, data);

    if (data.complete) {
      console.log('Job completed. Closing WebSocket connection.');
      socket.close();
    }
  };

  socket.onerror = (error) => {
    console.error('WebSocket Error:', error);
    socket.close();
  };

  socket.onclose = () => {
    console.log('WebSocket connection closed');
  };
};

const markVideoForDeletion = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/delete?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const data = await response.text();
  // console.log('data: ' + data);

  return data;
};

const unmarkVideoFromDeletion = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/restore?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const data = await response.text();
  // console.log('data: ' + data);

  return data;
};

const permanentlyDeleteVideo = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/erase?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const { data } = await response.json();
  // console.log('data: ' + data);

  return data;
};

/* -------------------------------------------------------------------------- */
/*                             Project Management                             */
/* -------------------------------------------------------------------------- */
const createProjectInBackend = async (project: string, description: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/create`;

  // Prepare the request body with project and description
  const requestBody = {
    project: project,
    description: description,
  };

  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Specify that we are sending JSON
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify(requestBody), // Convert the request body to JSON
  });

  const resp = await response.text();
  // console.log('resp: ' + resp);

  return resp;
};


const linkToProject = async (project: string, video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/allocate?project=${project}&video=${video}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const resp = await response.text();
  // console.log('resp: ' + resp);

  return resp;
};

const unlinkFromProject = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/deallocate?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const resp = await response.text();
  // console.log('resp: ' + resp);

  return resp;
};

const deleteProject = async (project: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/delete?project=${project}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const resp = await response.json();
  // console.log('resp: ' + resp);

  return resp;
};

const updateVideo = async (videoID: string, videoTitle: string | undefined, videoDescription: string | undefined) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/video/update`;
  const body: { video: string, title?: string; description?: string } = { video: videoID };

  if (videoTitle && videoTitle.trim() !== '') {
    body.title = videoTitle;
  }

  if (videoDescription && videoDescription.trim() !== '') {
    body.description = videoDescription;
  }

  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();
  // console.log('resp: ' + resp);

  return resp;
};


const shareProject = async (project: string, email: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/share?project=${project}&email=${email}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const resp = await response.json();
  // console.log('resp: ' + resp);

  return resp;
};

export {
  createProjectInBackend,
  deleteProject,
  getProfile,
  getSDKData,
  getThumbnail,
  getUploadLink,
  getUploadProgress,
  getVideo,
  getVideos,
  linkToProject,
  markVideoForDeletion,
  notifyServer,
  permanentlyDeleteVideo,
  shareProject,
  unlinkFromProject,
  unmarkVideoFromDeletion,
  uploadVideo,
  updateVideo,
};
